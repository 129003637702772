<template>
  <a-page-header @back="() => $router.go(-1)" title="ANEMIA NINIOS" class="pt-0 px-0">
    <template #extra>
      <a-breadcrumb>
        <a-breadcrumb-item><router-link to="/menu">Nutricional</router-link></a-breadcrumb-item>
        <a-breadcrumb-item>NIÑOS MENORES DE 5 AÑOS CON ANEMIA SEGÚN DISTRITO</a-breadcrumb-item>
      </a-breadcrumb>
    </template>
  </a-page-header>

  <a-form ref="formRefFilter" layout="horizontal" :model="formState">
    <div class="grid grid-flow-col">
      <div class="row-start-1 row-end-6">
        <a-form-item label="Año: " name="search" class="mb-0 w-full lg:w-60">
          <a-select class="w-60" v-model:value="formState.anio" @select="onSelectAnio">
            <a-select-option v-for="anio in anios" :value="anio" :key="anio">
              {{ anio }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </div>
      <div
        class="row-start-1 row-end-6 col-span-4 flex flex-col items-center"
        style="margin-top: -15px"
      >
        <div class="grid grid-flow-col">
          <!-- <div class="row-start-1 row-end-6 flex flex-col items-center">
            PERIODO INICIO
            <a-form-item label="" name="search" class="mb-0 w-full lg:w-60">
              <a-select class="w-60" v-model:value="formState.periodo_ini" @select="onSelectAnio">
                <a-select-option value="1">ENERO</a-select-option>
                <a-select-option value="2">FEBRERO</a-select-option>
                <a-select-option value="3">MARZO</a-select-option>
                <a-select-option value="4">ABRIL</a-select-option>
                <a-select-option value="5">MAYO</a-select-option>
                <a-select-option value="6">JUNIO</a-select-option>
                <a-select-option value="7">JULIO</a-select-option>
                <a-select-option value="8">AGOSTO</a-select-option>
                <a-select-option value="9">SETIEMBRE</a-select-option>
                <a-select-option value="10">OCTUBRE</a-select-option>
                <a-select-option value="11">NOVIEMBRE</a-select-option>
                <a-select-option value="12">DICIEMBRE</a-select-option>
              </a-select>
            </a-form-item>
          </div> -->
          <!-- <div class="row-start-1 row-end-6 flex flex-col items-center ml-5">
            PERIODO FIN
            <a-form-item label="" name="search" class="mb-0 w-full lg:w-60">
              <a-select class="w-60" v-model:value="formState.periodo_fin" @select="onSelectAnio">
                <a-select-option value="1">ENERO</a-select-option>
                <a-select-option value="2">FEBRERO</a-select-option>
                <a-select-option value="3">MARZO</a-select-option>
                <a-select-option value="4">ABRIL</a-select-option>
                <a-select-option value="5">MAYO</a-select-option>
                <a-select-option value="6">JUNIO</a-select-option>
                <a-select-option value="7">JULIO</a-select-option>
                <a-select-option value="8">AGOSTO</a-select-option>
                <a-select-option value="9">SETIEMBRE</a-select-option>
                <a-select-option value="10">OCTUBRE</a-select-option>
                <a-select-option value="11">NOVIEMBRE</a-select-option>
                <a-select-option value="12">DICIEMBRE</a-select-option>
              </a-select>
            </a-form-item>
          </div> -->
        </div>
      </div>
    </div>
  </a-form>

  <div class="overflow-x-auto w-full bg-white border-2 rounded-lg">
    <a-table
      :columns="columns"
      :data-source="data"
      size="small"
      :pagination="false"
      rowKey="idsienanemia"
      :loading="isLoading"
      :rowClassName="
        (record, index) =>
          index % 2 === 0 ? 'cursor-pointer bg-white' : 'cursor-pointer bg-gray-100'
      "
    >
      <template #rangepages="{ index }">
        <span> {{ index + 1 }} </span>
      </template>

      <template #acciones="{ record }">
        <div class="flex gap-2 items-center ml-5">
          <a-tooltip title="Editar Dato">
            <a-button size="small" @click="showModalEdit(record)">
              <template #icon><EditOutlined /></template>
            </a-button>
          </a-tooltip>
        </div>
      </template>
    </a-table>
  </div>

  <AnemiaNiniosAddEdit
    v-if="isVisible"
    :isVisible="isVisible"
    :data="registro"
    @closeModal="closeModal"
    @fetchData="fetchData"
  />
</template>

<script>
import { columns } from "./utilsAnemia";
import CienAnemiaApi from "@/api/sien_anemia";
import { ref, reactive, onMounted } from "vue";
import AnemiaNiniosAddEdit from "./AnemiaNiniosAddEdit.vue";
import { EditOutlined } from "@ant-design/icons-vue";

export default {
  components: {
    EditOutlined,
    AnemiaNiniosAddEdit
  },

  setup() {
    const data = ref([]);
    const isVisible = ref(false);
    const registro = ref(null);
    const anios = ref([]);

    const formState = reactive({
      anio: "",
      periodo_ini: "",
      periodo_fin: ""
    });

    function showModalEdit(record) {
      record.anio = formState.anio;
      record.periodo_ini = formState.periodo_ini;
      record.periodo_fin = formState.periodo_fin;

      registro.value = record;
      isVisible.value = true;
    }

    onMounted(() => {
      fetchAnios();
      fetchFiltros();
    });

    const fetchData = () => {
      CienAnemiaApi.getAllSienAnemia(formState.anio, formState.periodo_ini, formState.periodo_fin)
        .then((response) => {
          data.value = response.data;
        })
        .catch((err) => console.log(err));
    };

    const fetchFiltros = () => {
      CienAnemiaApi.getAllSienAnemiaFiltros()
        .then((response) => {
          formState.anio = response.data[0]["anio"];
          formState.periodo_ini = response.data[0]["periodo_ini"];
          formState.periodo_fin = response.data[0]["periodo_fin"];

          fetchData();
        })
        .catch((err) => console.log(err));
    };

    const closeModal = () => {
      isVisible.value = false;
    };

    const fetchAnios = () => {
      var anio = new Date().getFullYear();

      var oldanios = [];
      for (let index = 0; index < 4; index++) {
        oldanios.push(anio - index);
      }

      anios.value = oldanios;
    };

    const onSelectAnio = (anio) => {
      // formState.anio = anio;
      fetchData();
    };

    return {
      columns,
      data,
      formState,
      isVisible,
      closeModal,
      showModalEdit,
      registro,
      fetchData,
      onSelectAnio,
      anios
    };
  }
};
</script>
