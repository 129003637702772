export const columns = [
  {
    title: "N°",
    key: "index",
    width: 50,
    slots: { customRender: "rangepages" }
  },
  {
    title: "PROVINCIA",
    dataIndex: "provincia",
    key: "provincia"
  },
  {
    title: "DISTRITO",
    dataIndex: "distrito",
    key: "distrito"
  },
  {
    title: "UBIGEO",
    dataIndex: "id_ubigeo",
    key: "id_ubigeo"
  },
  {
    title: "N° DE EVALUADOS",
    dataIndex: "nro_evaluados",
    key: "nro_evaluados"
  },
  {
    title: "ANEMIA TOTAL",
    children: [
      {
        title: "N° DE CASOS",
        dataIndex: "nro_anemia_total",
        key: "nro_anemia_total",
        width: 150
      },
      {
        title: "%",
        dataIndex: "porc_anemia_total",
        key: "porc_anemia_total",
        width: 65
      }
    ]
  },
  {
    title: "ANEMIA LEVE",
    children: [
      {
        title: "N° DE CASOS",
        dataIndex: "nro_anemia_leve",
        key: "nro_anemia_leve",
        width: 150
      },
      {
        title: "%",
        dataIndex: "porc_anemia_leve",
        key: "porc_anemia_leve",
        width: 65
      }
    ]
  },
  {
    title: "ANEMIA MODERADA",
    children: [
      {
        title: "N° DE CASOS",
        dataIndex: "nro_anemia_moderada",
        key: "nro_anemia_moderada",
        width: 150
      },
      {
        title: "%",
        dataIndex: "porc_anemia_moderada",
        key: "porc_anemia_moderada",
        width: 65
      }
    ]
  },
  {
    title: "ANEMIA SEVERA",
    children: [
      {
        title: "N° DE CASOS",
        dataIndex: "nro_anemia_severa",
        key: "nro_anemia_severa",
        width: 150
      },
      {
        title: "%",
        dataIndex: "porc_anemia_severa",
        key: "porc_anemia_severa",
        width: 65
      }
    ]
  },
  {
    title: "OPCIONES",
    key: "acciones",
    slots: { customRender: "acciones" },
    width: 120
  }
];
