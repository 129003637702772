|
<template>
  <a-modal
    :visible="true"
    :title="title"
    @ok="handleOk"
    @cancel="handleCancel"
    @close="closeModal"
    class="w-20"
    okText="Guardar"
  >
    <a-form :model="formState" ref="formRef" v-bind="layout">
      <a-form-item label="NRO EVALUADOS: ">
        <a-input
          placeholder=""
          v-model:value="formState.nro_evaluados"
        />
      </a-form-item>

      <div class="max-w-lg rounded overflow-hidden shadow-lg">
        <div class="px-4">
          <div class="font-bold text-xl mb-2 text-center">ANEMIA LEVE</div>
          <a-form-item label="N° DE CASOS" name="casos" >
            <a-input
              placeholder=""
              v-model:value="formState.nro_anemia_leve"
            />
          </a-form-item>
          <a-form-item label="%" name="porcentaje">
            <a-input
              placeholder=""
              v-model:value="formState.porc_anemia_leve"
            />
          </a-form-item>
        </div>
      </div>

      <div class="max-w-lg rounded overflow-hidden shadow-lg mt-4">
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2 text-center">ANEMIA MODERADA</div>
          <a-form-item label="N° DE CASOS" name="casos">
            <a-input
              v-model:value="formState.nro_anemia_moderada"
              placeholder=""
            />
          </a-form-item>
          <a-form-item label="%" name="porcentaje">
            <a-input
              v-model:value="formState.porc_anemia_moderada"
              placeholder=""
            />
          </a-form-item>
        </div>
      </div>

      <div class="max-w-lg rounded overflow-hidden shadow-lg mt-4">
        <div class="px-6 py-4">
          <div class="font-bold text-xl mb-2 text-center">ANEMIA SEVERA</div>
          <a-form-item label="N° DE CASOS" name="casos">
            <a-input
              v-model:value="formState.nro_anemia_severa"
              placeholder=""
            />
          </a-form-item>
          <a-form-item label="%" name="porcentaje">
            <a-input
              v-model:value="formState.porc_anemia_severa"
              placeholder=""
            />
          </a-form-item>
        </div>
      </div>

    </a-form>
  </a-modal>
</template>

<script>
import { reactive, toRaw, ref, onMounted } from "vue";
import CienAnemiaApi from "@/api/sien_anemia";
import { notification } from "ant-design-vue";

export default {
  props: {
    data: {
      type: Object
    }
  },
  emits: ["closeModal", "fetchData"],
  setup(props, { emit }) {
    const formRef = ref();
    /* eslint-disable  vue/no-setup-props-destructure*/
    const data = props.data; // se define porque tiene que ser unico en editar

    const formState = reactive(data);
    const title = ref("");

    const periodos = ["ENERO","FEBRERO","MARZO","ABRIL","MAYO","JUNIO","JULIO","AGOSTO","SETIEMBRE","OCTUBRE","NOVIEMBRE","DICIEMBRE"];

    const closeModal = () => {
      emit("closeModal");
    };

    const handleOk = () => {
      formRef.value
        .validate()
        .then(async () => {
          const payload = toRaw(formState);
          let response = {};
          let msgNotificacion = "";
          try {
            const idsienanemia = toRaw(formState)?.idsienanemia;

            response = await CienAnemiaApi.editOne(payload, idsienanemia);
            msgNotificacion = "Se ha sido modificada correctamente.";

            if (response.status == 200) {
              notification.success({
                message: "Operación exitosa",
                description: msgNotificacion,
              });
            }
            emit("closeModal");
            emit("fetchData");
          } catch (error) {
            notification.error({
              message: "Operación inválida",
              description: "",
              // description: error.message
            });
          }
        })
        .catch((err) => console.log(err));
    };

    onMounted(() => {
      title.value = "PERIODO: " + periodos[data.periodo_ini - 1]+ " - " + periodos[data.periodo_fin - 1] + " " + data.anio;
    });

    const handleCancel = () => {
      emit("fetchParametros");
      emit("closeModal");
    };

    const layout = {
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 25,
        offset: 1
      }
    };
    return {
      handleOk,
      closeModal,
      handleCancel,
      formState,
      formRef,
      layout,
      title
    };
  }
};
</script>
